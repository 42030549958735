<template>
	<div>
		<h1>Editar coleta</h1>

		<h2 v-if="form && form.commission_id" class="red--text">
      Comissão fechada!
      <router-link :to="`/comissoes/${form.commission_id}`">Clique para acessar</router-link>
    </h2>

		<form-collect :loading="loading" @save="save" :form.sync="form" />
	</div>
</template>

<script>

import FormCollect from './FormCollect';

	export default {
		components: {
			FormCollect,
		},
		data() {
			return {
				loading: false,
				form: null,
			}
		},
		mounted() {
			this.getCollect();
		},
		methods: {
			save() {
				this.loading = true;
				this.$http.put(`api/collects/${this.$route.params.id}`, this.form)
					.then(() => {
						this.loading = false;
						
						this.$toast.success('Salvo com sucesso');
					}).catch(err => {
						this.loading = false;
						console.log(err);

						this.$toast.error('Erro ao salvar');
					});
			},
			getCollect() {
				this.$http.get(`api/collects/${this.$route.params.id}`)
					.then(res => {
						this.form = res.data;
					}).catch(err => {
						console.log(err);

						this.$toast.error('Erro ao salvar');
					});
			}
		}
	}
</script>